<template>
  <div>
    <!-- 顶部搜索栏 -->
    <el-row>
      <el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container flx-row ali-c">
          <el-input
            placeholder="请输入门店名称"
            class="search-input"
            v-model="searchObj.keyword"
          ></el-input>
          <el-select
            placeholder="全部状态"
            class="search-input ml-15"
            v-model="searchObj.commodity_status"
          >
            <el-option
              v-for="(item, index) in commodity_status"
              :key="index"
              :label="item.name"
              :value="index"
            ></el-option>
          </el-select>

          <el-button
            type="primary"
            icon="el-icon-search"
            class="ml-15"
            @click="search"
            >查询</el-button
          >
          <el-button icon="el-icon-refresh" class="ml-15" @click="reset"
            >重置</el-button
          >
        </div>
      </el-col>
    </el-row>
    <!-- 表格内容 -->
    <el-row class="mt-24">
      <el-col el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container">
          <!-- 操作栏 -->
          <div class="flx-row ali-c js-btw">
            <div class="flx-row ali-c">
              <router-link
                :to="{ name: 'Stores_new', params: { addFlag: true } }"
              >
                <el-button class="ml-15" type="primary">新增门店</el-button>
              </router-link>
            </div>
          </div>
          <!-- 表格 -->
          <el-table class="mt-24 member-table" :data="tableData">
            <el-table-column
              label="门店名称"
              prop="storeName"
            ></el-table-column>
            <el-table-column label="门店图片">
              <template slot-scope="scope">
                <el-avatar
                  :src="picsPath(scope.row.storeCover)"
                  shape="square"
                  alt="加载失败"
                ></el-avatar>
              </template>
            </el-table-column>
            <el-table-column label="门店地址" prop="address"></el-table-column>
            <el-table-column
              label="联系方式"
              prop="storeTelephone"
            ></el-table-column>
            <el-table-column
              label="创建时间"
              prop="creationTime"
            ></el-table-column>
            <el-table-column label="状态">
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.status"
                  :active-value="1"
                  :inactive-value="0"
                  active-text="上架"
                  inactive-text="下架"
                  @change="upDownShelf($event, scope.row.id)"
                ></el-switch>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <div class="flx-row ali-c">
                  <router-link
                    :to="{
                      name: 'Stores_new',
                      params: { addFlag: false, storeId: scope.row.id },
                    }"
                  >
                    <el-link type="primary" :underline="false">
                      <i class="el-icon-edit-outline"></i>
                      编辑
                    </el-link> </router-link
                  ><el-link
                    type="danger"
                    :underline="false"
                    class="ml-10"
                    @click="deleteStoreById(scope.row.id)"
                  >
                    <i class="el-icon-delete-solid"></i>
                    删除
                  </el-link>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <Pagination
            :total="total"
            :pageNum="pageNum"
            :pageSize="pageSize"
            :pageSizes="pageSizes"
            @changePage="search"
            @syncPageData="syncPageData"
          />
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination/index";
import {
  getStoreList,
  upDownShelfStore,
  deleteStore,
} from "@/api/service_center/stores";
export default {
  name: "store_list",
  components: {
    Pagination,
  },
  data() {
    return {
      // 顶部搜索栏数据
      searchObj: {
        keyword: "",
        commodity_status: null,
      },
      // 活动状态列表,用于筛选
      commodity_status: [
        {
          name: "已下架",
        },
        {
          name: "上架中",
        },
      ],
      // 会员等级列表,用于筛选
      commodity_cate: [
        {
          name: "商品",
        },
        {
          name: "奖品",
        },
        {
          name: "优惠券",
        },
      ],
      // 会员表格数据内容
      tableData: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 50, 100],
    };
  },
  created() {
    this.search();
  },
  methods: {
    // 搜索事件
    search() {
      let params = {
        keyword: this.searchObj.keyword,
        page: this.pageNum,
        size: this.pageSize,
        status: this.searchObj.commodity_status,
      };
      getStoreList(params).then((res) => {
        if (res.data.code == 200 && res.data.body) {
          this.tableData = res.data.body.list;
          this.total = res.data.body.total;
        }
      });
    },
    // 重置搜索关键词
    reset() {
      this.searchObj = {
        keyword: "",
        commodity_status: null,
      };
      this.search();
    },
    // 会员多选事件
    tableSelect(e) {
      // // console.log(e);
      this.multiSeletction = e;
    },
    // 优惠券多选事件
    selectCou(e) {
      this.selectedCou = e;
    },
    upDownShelf(e, id) {
      upDownShelfStore(id, e).then((res) => {
        this.$message({
          message: "操作成功",
          type: "success",
        });
      });
    },
    //逻辑删除门店
    deleteStoreById(storeId) {
      this.$confirm("此操作将删除该门店, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          // console.log("进来了吗");
          deleteStore(storeId).then((res) => {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.search();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    syncPageData(data) {
      this.pageNum = data.num;
      this.pageSize = data.size;
    },
  },
};
</script>
<style lang="scss" scoped>
.member-table {
  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}
</style>
